// Mobile menu toggler
let menuToggler = document.getElementById("menuToggler");
let mobileMenu = document.getElementById("mobileMenu");

function menuToggle() {
    menuToggler.classList.toggle('opened');
    mobileMenu.classList.toggle('opened');
}

menuToggler.addEventListener("click", function () {
    menuToggle();
})

// Splide images slider on homepage
let hpSliderElementId   = 'hpSlider';
var hpSliderElement     = document.getElementById(hpSliderElementId);

if (typeof(hpSliderElement) != 'undefined' && hpSliderElement != null) {
    new Splide('#' + hpSliderElementId, {
        type: 'fade',
        rewind: true,
        perPage: 1,
        autoplay: true,
        interval: 6000,
    }).mount();
}

// Splide Reviews slider
var reviewEl = document.getElementsByClassName('reviewsSlider')
if (typeof(reviewEl) != 'undefined' && hpSliderElement != null) {
    new Splide('.reviewsSlider', {
        rewind: true,
        interval: 3000,
        width: '100%',
        perMove: 1,
        gap: '2em',
        pagination: false,
        perPage: 3,
        breakpoints: {
            640: {
            perPage: 1,
            },
            991: {
                perPage: 2,
                },
        }
    }).mount();
}

// Back to top button
const toTop = document.querySelector(".back-to-top");

/*
window.addEventListener("scroll", function(){
    if(window.pageYOffset > 100){
        toTop.classList.add("back-to-top--active")
    }
    else{
        toTop.classList.remove("back-to-top--active")
    }
})
*/

toTop.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
})